import React from 'react';

const RoundedImage = ({ imageUrl, radius }) => {
  return (
    <div
      style={{
        borderRadius: `${radius}px`,
        overflow: 'hidden',
      }}
    >
      <img src={imageUrl} alt="Rounded image" />
    </div>
  );
};

export default RoundedImage;
