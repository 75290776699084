import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ResultsRow from './results_row.js';

import house from '../images/house.gif';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

// TODO: next button
// set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
//   next: true,
// });


function Results(props) {
  const [users, setUsers] = useState();
  const [rows, setRows] = useState();
  const [price, setPrice] = useState();


    function addCommas(number) {
      if (number){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

  useEffect(() => {
      const uref = ref(database, 'games/' + props.game_id +"/users/");
      onValue(uref, (snapshot) => {
        const data = snapshot.val();
        setUsers(data);
        const user_results =  Object.keys(data).map((user, step) => {
          setPrice(data[user].price);
                return (
                  <ResultsRow user={user} key={user} guess={data[user].guess} price={data[user].price} accuracy={data[user].accuracy} absolute={data[user].absolute}/>
                  )
              });
        setRows(user_results);
      });
      return () => off(uref)
    }, []);


  return (
    <Box sx={{ mt: 2, display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
      <Typography component="h1" variant="h6" align="center">
        The price was: ${addCommas(price)}
      </Typography>
      {rows}
        </Box>
  );
}

export default Results;
