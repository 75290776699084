import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import axios from 'axios';


function InitialGame(props) {
  const [price, setPrice] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [listings, setListings] = useState(null);

  async function sendData(data) {
    try {
        const response = await fetch('http://localhost:5000/api/sales', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const json = await response.json();
        console.log(json);
        set(ref(database, '/games/' + props.game_id + "/state/listings/"), {
          city: data,
          listings: json,
          index: 0,
          loading: false,
        });
        set(ref(database, '/games/' + props.game_id + "/state/view/"), {
          view: "main",
        });
        props.setShow(false);
        // TODO: get partiluar listing info
      } catch (error) {
        console.error(error);
      }
    }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("setting loading");
    set(ref(database, '/games/' + props.game_id + "/state/listings/"), {
      loading: true,
    });
    const data = new FormData(event.currentTarget);
    let guess = data.get('city').toLowerCase();
    sendData(guess)
  }

  return (
    <Box sx={{ mt: 12 }}>
          <Box sx={{ mt: 2 }}>
            <Typography component="h1" variant="h5" align="center">
              To get started, enter a city
            </Typography>

          </Box>
            <Container component="main" maxWidth="sm" sx={{ mb: 4}}>
              <Paper elevation="10" variant="elevation" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Box component="form" onSubmit={handleSubmit}  noValidate sx={{ mt: 1 }}>
                  <TextField

                    id="city"
                    name="city"
                    fullWidth="true"
                    justifyContent="center"
                    align="center"
                    variant="outlined" />
                </Box>
              </Paper>
            </Container>
        </Box>
  );
}

export default InitialGame;
