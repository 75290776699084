import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MainGame from './game_main.js';
import RoundedImage from './rounded_image.js';
//import Carousel from './carousel_gpt';
//import Carousel3 from './carousel3';
import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";
import GuessInput from './guess_input';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


function Listing(props) {
  const [goToSlide, setGoToSlide] = useState(0);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showNavigation, setShowNavigation] = useState(true);

  function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const callback = function(index){
    console.log("callback",index);
  }


  const goToInitialpage = () => {
    props.setShow(true);
  }
  let slides = [];
  for (const url of props.listings[props.index]["photos"]) {
    slides.push({ key: url, content: <RoundedImage  imageUrl={url} radius={10}/>});
  }
  slides.map((slide, index) => {
    return { ...slide, onClick: () => goToSlide(index)}});

  const price = props.listings[props.index]["price"]
  const price_string = addCommas(price);
  const sqft_string = addCommas(props.listings[props.index]["sqft"]);


  return (
    <Box sx={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
      <Button variant="contained" onClick={goToInitialpage}>Enter new city</Button>
      <Typography variant="h5" align="center" sx={{mt:5}}><b>City:</b> {props.city}</Typography>
      <Typography variant="h5"><b>Bedrooms:</b> {props.listings[props.index]["bedrooms"]}</Typography>
      <Typography variant="h5"><b>Bathrooms:</b> {props.listings[props.index]["bathrooms"]}</Typography>
      <Typography variant="h5"><b>Square Feet:</b> {sqft_string}</Typography>
      <GuessInput game_id={props.game_id} price={price} price_string={price_string} username={props.username}/>
      <div style={{ width: "80%", height: "500px", margin: "0 auto", marginBottom:100 }}>
        <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={offsetRadius}
            showNavigation={showNavigation}
            animationConfig={config.gentle}
          />
        </div>
    </Box>
  );
}

// <Typography>Price: ${price_string}</Typography>
// <Typography>Zestimate: {props.listings[0]["zestimate"]}</Typography>
// <Typography>URL: {props.listings[0]["url"]}</Typography>

export default Listing;
