import React, { useCallback, useEffect, useRef, useState } from "react";
import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RoundedImage from './rounded_image.js';
import PhoneInputComponent from './submission_input';
//import audio from '../../images/campfire-1.mp3';
import './main_game.css';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";



import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const shareUrl = "renterswoe.com";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styles = {


  text: {
    fontSize: '2rem', // Increase the font size
    fontWeight: 'bold', // Make the text bold
    textAlign: 'center',
    marginBottom: '1rem',
    // textShadow: `
    //   -1px -1px 0 #000,
    //   1px -1px 0 #000,
    //   -1px 1px 0 #000,
    //   1px 1px 0 #000
    // `, // Add text shadow for black outline
    background: 'linear-gradient(90deg, #c42727, #e25822, #e9791A, #F19A11, #f8ba09, #ffdb00)',  // Add gradient
    backgroundClip: 'text',  // Apply gradient only to text
    WebkitBackgroundClip: 'text',
    color: 'transparent',  // Make the original text color transparent
    animation: 'gradient 5s infinite linear',  // Apply animation
    backgroundSize: '200% 100%',  // This ensures the animation works as expected
    // ... other styles
  },

  keyframes: `
  @keyframes gradient {
    0% { background-position: 100% }
    100% { background-position: 0% }
  }
`,
};


export default function MainGame(props) {
    const [open, setOpen] = React.useState(false);
    const [showNavigation, setShowNavigation] = useState(true);
    const [counter, setCounter] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [guess, setGuess] = React.useState();
    const [accuracy, setAccuracy] = React.useState(0);
    const [bigMessage, setBigMessage] = React.useState();
    const [smallMessage, setSmallMessage] = React.useState();
    const [title, setTitle] = React.useState();

    const onclick = (index) => {
      props.setIndex(index);
    }

    const handleClose = () => {
      // let url = chooseRandomItem(props.url_list);
      // console.log(url);
      // props.resetL();
      // props.sendData(url);
      // let property = chooseRandomItem(props.properties);
      // console.log(property);
      props.setPropIndex();
      props.resetL();
      // props.sendData(property);

      setModalOpen(false);
    }


      function chooseRandomItem(items) {
        return items[Math.floor(Math.random() * Object.keys(items).length)][0];
      }

    function calculateAccuracy(guess, real) {
      let modifiedGuess = guess.split("").filter(char => char !== "$").join("");
      let modifiedGuess2 = modifiedGuess.split("").filter(char => char !== ",").join("");
      let guessNum = +modifiedGuess2;
      let accuracy;
      if (guessNum > real) {
        accuracy = 1 - (guessNum - real) / guessNum
      } else if (real > guessNum) {
        accuracy = 1 - (real - guessNum) / real
      } else if (real == guessNum) {
        accuracy = 1
      }
      if (accuracy > .7) {
        setBigMessage("Close");
        setTitle("Homeowner");
        setSmallMessage("You probably own a home yourself, that's why you're so accurate...");
      } else if (real > guessNum) {
        setBigMessage("WRONG");
        setTitle("Hopelessly Delusional Renter");
        setSmallMessage("You probably can't even afford the down payment");
      } else if (guessNum > real) {
        setBigMessage("Way Over");
        setTitle("Delusional Homeowner");
        setSmallMessage("Boomers can only dream of such high prices...");
      }

      props.updateAccuracyList(accuracy);
      if (accuracy > props.bestScore) {
        props.setBest(accuracy)
      }
      if (accuracy < props.worstScore) {
        props.setWorst(accuracy)
      }
      let percent = (accuracy * 100).toFixed(2) + "%";
      return percent;
    }

    const openModal = (guess) => {
      setGuess(guess);
      setAccuracy(calculateAccuracy(guess, props.price));
      setModalOpen(true);
    }

    let slides = [];
    let index= 0;
    for (const photo of props.photos) {
      let url = photo["mixedSources"]["jpeg"][0]["url"];
      slides.push({ key: url, content: <RoundedImage imageUrl={url} onclick={onclick} ind={index} radius={20}/>});
      index = index + 1;
    }
   const goRight = () => {
      props.setIndex(props.index + 1);
    };
    const goLeft = () => {
       props.setIndex(props.index - 1);
     };

     function addCommas(number) {
       if (number){
         return '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       }
     }

    useEffect(() => {
      const keyDownHandler = event => {
        if (event.key === 'ArrowRight') {
          event.preventDefault();

          // 👇️ your logic here
          goRight();
        }
        if (event.key === 'ArrowLeft') {
          event.preventDefault();

          // 👇️ your logic here
          goLeft();
        }
        if (event.key === 'Enter') {
          event.preventDefault();
          console.log("enter");
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, [props.index]);

    // let aud = new Audio(audio);
    // aud.play();
    // aud.loop = true;


    return (
      <Box sx={{display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'}}>

        <div style={{ width: "75%", height: "300px", margin: "0 auto", marginBottom: 5 }}>
              <Carousel
                  slides={slides}
                  height="800px"
                  width="50%"
                  margin="0 auto"
                  offset={2}
                  showArrows={false}
                  goToSlide={props.index}
                  animationConfig={config.gentle}
                />
          <Box sx={{display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', marginTop: 0}}>
          <Typography>Guess the price </Typography>
            <PhoneInputComponent openModal={openModal} closeModal={handleClose} open={modalOpen}/>


            <Grid container spacing={2} sx={{mt: 0}}>
                <Grid item xs={12} md={6}>
                  <Box sx={{display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'}}>
                    <Typography variant="h6">🛏 Bedrooms: {props.bedrooms}</Typography>
                    <Typography variant="h6" sx={{mt: 0}}> 🛁 Bathrooms: {props.bathrooms}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'}}>
                  <Typography variant="h6"> 🦶 Square Feet: {props.sqft}</Typography>
                  <Typography variant="h6" sx={{mt: 0}}>🌇 City: {props.city}, {props.state}</Typography>
                  </Box>
                </Grid>
            </Grid>
            <br/>
            <Typography >{props.description} </Typography>
          </Box>
          </div>
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >

          <Box sx={{ ...style, width: 200 , display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'}}>
            <div id="parent-modal-title">You guessed that this {props.city}, {props.state} house cost
            </div><div class="price">{guess} </div>
            <h1>{bigMessage}</h1>
            <div id="parent-modal-title">This house actually cost
            </div><div class="price">{addCommas(props.price)}</div>
            <br/>
            <div id="parent-modal-title">You've earned the title </div><div style={styles.text}>{title}</div>
            <h4 id="parent-modal-title">{smallMessage}</h4>
            <h2 id="parent-modal-title">Accuracy: {accuracy}
            </h2>
            <Box sx={{display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'}}>

            <FacebookShareButton
                url={shareUrl}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <div className="Demo__some-network">
                <FacebookMessengerShareButton
                  url={shareUrl}
                  appId="521270401588372"
                  className="Demo__some-network__share-button"
                >
                  <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
              </div>
              <div className="Demo__some-network">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="Demo__some-network">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>
      <div className="Demo__some-network">
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <RedditShareButton
               url={shareUrl}
               title={title}
               windowWidth={660}
               windowHeight={460}
               className="Demo__some-network__share-button"
             >
               <RedditIcon size={32} round />
             </RedditShareButton>

            </Box>
            </Box>
          </Modal>
    </Box>
    );
  }
