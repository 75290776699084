import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Input = styled.input`
  font-size: 30px;
  padding: 10px;
  margin: 10px;
  background: white;
  border: 1px solid brown;
  border-radius: 3px;
  ::placeholder {
    color: palevioletred;
  }
`;

export default function PhoneInputComponent(props) {
  const [inputValue, setInputValue] = React.useState('$');

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (props.open) {
          props.closeModal();
        } else {
          props.openModal(inputValue);
        }
        setInputValue("$");
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [inputValue]);

  const handleInput = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber);
  };


    return (
      <div>
      <Input onChange={(e) => handleInput(e)} value={inputValue} />
      </div>
    );
  }


function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return `$${phoneNumber}`;
  };

  if (phoneNumberLength == 4) {
    return `$${phoneNumber.slice(0,1)},${phoneNumber.slice(1)}`;
  };
  if (phoneNumberLength == 5) {
    return `$${phoneNumber.slice(0,2)},${phoneNumber.slice(2)}`;
  };
  if (phoneNumberLength == 6) {
    return `$${phoneNumber.slice(0,3)},${phoneNumber.slice(3)}`;
  };
  if (phoneNumberLength == 7) {
    return `$${phoneNumber.slice(0,1)},${phoneNumber.slice(1,4)},${phoneNumber.slice(4)}`;
  };
  if (phoneNumberLength == 8) {
    return `$${phoneNumber.slice(0,2)},${phoneNumber.slice(2,5)},${phoneNumber.slice(5)}`;
  };
  if (phoneNumberLength == 9) {
    return `$${phoneNumber.slice(0,3)},${phoneNumber.slice(3,6)},${phoneNumber.slice(6)}`;
  };

  // // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // // the formatted number
  // if (phoneNumberLength < 7) {
  //   return `$${phoneNumber.slice(0, 3)},${phoneNumber.slice(3)}`;
  // }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `$${phoneNumber.slice(0, 3)},${phoneNumber.slice(
    3,
    6
  )},${phoneNumber.slice(6, 9)}`;
}
