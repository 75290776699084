import logo from './logo.svg';
import './App.css';
import GameShell from './game_shell.js';
import Landing from './Home/landing.js';
import LobbyForm from './Lobby/lobby_form.js';
import GameContainer from './SinglePlayer/game_container.js';
import LandingShell from './SinglePlayer/landing_shell.js';
import {Routes, Route} from 'react-router-dom';

function App() {


  return (
    <div>

      <Routes>
        <Route path='/' element={<LandingShell/>}></Route>
        <Route path='gameshell' element={<GameShell/>}></Route>
        <Route path="/games/:gameId/:userId" element={<GameShell />} />
        <Route path='form' element={<LobbyForm/>}/>
        <Route path='/g/:userId' element={<GameContainer/>}/>
      </Routes>
    </div>
  );
}

export default App;
