import * as React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";


import {useEffect, useState} from "react";

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";




export default function LobbyForm(props) {
  // Whether the game has started or is in the lobby
  const [viewing, setViewing] = useState(true); // Todo get value from databse
  const [users, setUsers] = useState();
  const [gameId, setGameId] = useState();
  const [href, setHref] = useState();
  const [gameError, setGameError] = useState(false);
  const [nameError, setNameError] = useState(false);
  let navigate = useNavigate();

  const setU = (u) => {
    setUsers(u);
  }


  // TODO: If admin, get a button to move forward
  // TODO: check if user already in lobby

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let user_name = data.get('username');
    let game_id = data.get('gameid');
    if (user_name == ''){
      setNameError(true);
      return
    } else {
      setNameError(false);
    }
    if (game_id == '') {
      setGameError(true);
      return
    } else {
      setGameError(false);
      // TODO: check if a valid game id
    }
    setGameId(game_id);
    set(ref(database, 'games/' + game_id +"/users/" + user_name), {
      score: 0,
    });
    let h = "/games/" + game_id + "/" + user_name
    setHref(h);
    // Somewhere in your code, e.g. inside a handler:
    navigate(h);
  };
  // TODO: check if admin to show the start game button

  return (
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Box sx={{ mt: 12 }}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>

          <TextField
            margin="normal"
            required
            fullWidth
            id="gameid"
            label="Game ID"
            name="gameid"
            autoFocus
            error={gameError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Display Name"
            name="username"
            error={nameError}
          />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="secondary"
              href={href}
            >
              Enter Game Lobby
            </Button>

          </Box>
          </Box>
        </Box>
      </Container>
  );
}
