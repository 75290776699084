import useMediaQuery from '@mui/material/useMediaQuery';
import Container from './Web/container.js';
import MobileContainer from './Mobile/container.js';


function GameContainer(props) {
  const isSScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isMScreen = useMediaQuery(theme => theme.breakpoints.down("md"));


  let show;
  if (isMScreen) {
    if (isSScreen) {
      show = <MobileContainer/>
    } else {
      show = <MobileContainer/>
    }
  } else {
    show = <Container/>
  }


  const small = isSScreen ? "small" : "big";

  return (
      <div>
        {show}
      </div>
  );

}

///games/4

export default GameContainer;
