import React, { useEffect } from 'react';
import {useState} from "react";

import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { pink } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Guess = (props) => {
  const [show, setShow] = useState(true);

  // TODO: pass guess and correct guess in
  let icon;
  if (props.next == false) {
    icon = <CheckIcon sx={{ color: green[500] }}/>
  }

    let row = <div>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Typography component="h1" variant="h6" align="center">
                {props.user}
              </Typography>
              {icon}
            </Stack>
          </div>


  return (
    <Box sx={{ mt: 2 }} align="center">
      {row}
    </Box>
  );
};

export default Guess;
