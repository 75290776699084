import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LandingShell(props) {
  let navigate = useNavigate();

  function generateRandomUserId() {
      // Generate a random number between 1 and 1000000
      const randomNumber = Math.floor(Math.random() * 1000000) + 1;

      // Convert the number to a string and pad it with leading zeros to ensure it is 6 digits long
      const userId = randomNumber.toString().padStart(6, '0');

      // Return the user ID
      return userId;
  }
  //TODO: decide based on screen size whether to render mobile or web

  useEffect(() => {
        const userId = generateRandomUserId();
        let h = "/g/" + userId
        navigate(h);
      });

    return (
    <div/>
    );
  }
