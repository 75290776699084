import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import emoji_pic from '../../images/emoji_style2.png';
import Arrow from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';


export default function RightSidebar(props) {
    const [open, setOpen] = React.useState(false);

    const styles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
    };

    const clickArrow = () => {
      // let url = chooseRandomItem(props.url_list);
      // console.log(url);
      // props.resetL();
      // props.sendData(url);
      // let property = chooseRandomItem(props.properties);
      // console.log(property);
      props.setPropIndex();
      props.resetL();
      // props.sendData(property);
    }

      function chooseRandomItem(items) {
        return items[Math.floor(Math.random() * Object.keys(items).length)][0];
      }

    return (
    <div>
      <Box>
      <Box sx={{display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', mb: 2}}>
      <img src={emoji_pic} width="230" />
        <Typography variant="h4"
        sx={{display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', mb: 2}}>Accuracy <b>{props.score}</b></Typography>
        <Typography variant="h6">Best: {(props.bestScore * 100).toFixed(2) + "%"}</Typography>
        <Typography variant="h6">Worst: {(props.worstScore * 100).toFixed(2) + "%"}</Typography>
        <IconButton aria-label="play/pause" onClick={clickArrow}>
        <Arrow sx={{ height: 100, width: 100}}/>
        </IconButton>
      </Box>
      </Box>
    </div>
    );
  }
