import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InitialPage from './initial_page.js';
import Listing from './listing.js';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import axios from 'axios';
import spinning_house from '../images/spinning_house.gif';


function MainGame(props) {
  const [listings, setListings] = useState(null);
  const [city, setCity] = useState(null);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(true);


  useEffect(() => {
    const lref = ref(database, '/games/' + props.game_id + "/state/listings/");
    onValue(lref, (snapshot) => {
      const data = snapshot.val();
      setListings(data.listings);
      setCity(data.city);
      setIndex(data.index);
      props.setIndex(data.index);
      props.setCity(data.city);
      props.setListings(data.listings);

      if (data.loading) {
        setLoading(true);
        props.setLoading(true);
      } else {
        setLoading(false);
        setShowInput(false);
        props.setLoading(false);
      }
    });
    return () => off(lref)
  }, []);

  // todo listen to the database to get pictures/listings/etc

  const setShow = (b) => {
    setShowInput(b);
  }

  const goToInitialpage = () => {
    setShow(true);
  }

  let render;
  if (loading) {
    render = <div>
              <img src={spinning_house} width="400"/>
              <Typography variant="h5" align="center" >Loading...</Typography>
            </div>;
  } else if (showInput) {
    render = <InitialPage setShow={setShow} game_id={props.game_id} username={props.username}/>
  } else {
    render = <Listing setShow={setShow} city={city} index={index} listings={listings} game_id={props.game_id} username={props.username}/>
  }

  return (
    <Box sx={{ mt: 2, display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
            {render}
        </Box>
  );
}

export default MainGame;
