import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Arrow from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';


export default function RightSidebar(props) {
    const [open, setOpen] = React.useState(false);



    const clickArrow = () => {
      // let url = chooseRandomItem(props.url_list);
      // console.log(url);
      // props.resetL();
      // props.sendData(url);
      // let property = chooseRandomItem(props.properties);
      // console.log(property);
      props.setPropIndex();
      props.resetL();
      // props.sendData(property);
    }

      function chooseRandomItem(items) {
        return items[Math.floor(Math.random() * Object.keys(items).length)][0];
      }

    return (
    <div>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={0} sx={{ mt: 0 }}>
        <Grid item xs={6} md={6}>
        <Box sx={{ mt:2 }}>
          <Typography variant="h5">Accuracy <b>{props.score}</b></Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <IconButton aria-label="play/pause" onClick={clickArrow}>
            New House <Arrow sx={{ height: 40, width: 40 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
    </div>
    );
  }
