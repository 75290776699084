import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MainGame from './game_main.js';
import Results from './results.js';

function MainContainer(props) {

  let render;
  if (props.view == "results") {
    render = <Results game_id={props.game_id}/>
  } else {
    render = <MainGame game_id={props.game_id} setLoading={props.setLoading} setCity={props.setCity} setListings={props.setListings} setIndex={props.setIndex} username={props.username} view={props.view}/>
  }

  return (
    <Box sx={{ mt: 2, display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
            {render}
        </Box>
  );
}

export default MainContainer;
