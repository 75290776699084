import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Guess from './guess.js';

import house from '../images/house.gif';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


function Sidebar(props) {
  const [users, setUsers] = useState();

  useEffect(() => {
      const uref = ref(database, 'games/' + props.game_id +"/users/");
      onValue(uref, (snapshot) => {
        const data = snapshot.val();
        const players =  Object.keys(data).map((user, step) => {
                return (
                  <Guess user={user} key={user} next={data[user].next} correct={data[user].correct}/>
                  )
              });
        setUsers(players);
        //check to see if everyone has submitted
        // TODO: move this check somewhere else.. causes problems here
      //   let num = players.length; //todo: do this check somewhere else...
      //   let counter = 0;
      //   Object.entries(data).forEach(([k,v]) => {
      //     if(data[k].next == false){
      //       counter = counter + 1;
      //     }
      //   });
      // if (counter == num) {
      //   set(ref(database, '/games/' + props.game_id + "/state/view/"), {
      //     view: "results",
      //   });
      // }
    });

      return () => off(uref)
    }, []);

    function nextListing() {
      if (props.view == "results") {
        Object.entries(users).forEach(([k,v]) => {
          let user = users[k].props.user;
          set(ref(database, 'games/' + props.game_id +"/users/" + user), {
            next: true,
          });
        });
        set(ref(database, '/games/' + props.game_id + "/state/view/"), {
          view: "main",
        });
        console.log(props.index);
          if (props.index < 4) { //TODO: remove this and iterate all the way through
            console.log("adding to index");
            set(ref(database, 'games/' + props.game_id +"/state/listings/"), {
              index: props.index + 1,
              listings: props.listings,
              city: props.city,
              loading: props.loading,
            });
          }
      } else {
        set(ref(database, '/games/' + props.game_id + "/state/view/"), {
          view: "results",
        });
      }
    }


  return (
    <Box sx={{ mt: 2, display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
    <img src={house} width="200"/>
    <Box sx={{ mt: 2, mb:4}}>
    players: {users}
    </Box>
    <Button color="primary" size="large" variant="contained" onClick={nextListing}>Next</Button>
        </Box>
  );
}

export default Sidebar;
