import React from 'react';
import {Route, Link, Routes, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {useEffect, useState} from "react";
import GameContainer from './Game/container.js'

import database from './firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import { Carousel } from '@trendyol-js/react-carousel';


// TODO: check if game id is real, check game type, redirect to correct game lobby based on game type

export default function GameShell() {
  const params = useParams();
  const [userId, setUserId] = useState(params.userId);
  const [gameId, setGameId] = useState(params.gameId);
  const [type, setType] = useState();
  const [started, setStarted] = useState();

  useEffect(() => {
    const uref = ref(database, 'valid_games/' + gameId );
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      setType(data.type);
      setStarted(data.started);
    });
    return () => off(uref)
  }, []);

  let container;
  if (type == "zillow") {
    container = <GameContainer game_id={gameId} user_id={userId} started={started}/>
  } else {
    container = <div> Loading...........................<br/> <br/> Make sure you have entered a valid game code </div>
  }

  return (
    <Box>
      {container}
      </Box>
  );
}
