import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MainContainer from './main_container.js';
import Sidebar from './sidebar.js';


import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


function GameContainer(props) {
  const [view, setView] = useState();
  const [index, setIndex] = useState();
  const [listings, setListings] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      const vref = ref(database, 'games/' + props.game_id +"/state/view/");
      onValue(vref, (snapshot) => {
        const data = snapshot.val();
        setView(data.view);
      });
      return () => off(vref)
    }, []);

    const sIndex = (i) => {
      setIndex(i);
    }

    const sLoading = (l) => {
      setLoading(l);
    }

    const sCity = (c) => {
      setCity(c);
    }

    const sListings = (l) => {
      setListings(l);
    }


  return (
    <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Box sx={{mt:4, ml:2, mr: 2}}>
                  <Typography variant="h5">
                    #{props.game_id} <br/>
                    {props.user_id} <br />
                  </Typography>
                </Box>
                </Grid>
              <Grid item xs={12} md={10}>
          <Box sx={{mt:3, ml:2}}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
              <MainContainer game_id={props.game_id} setIndex={sIndex} setLoading={sLoading} setCity={sCity} setListings={sListings} username={props.user_id} view={view}/>
            </Grid>
            <Grid item xs={12} md={3}>
            <Box sx={{display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'}}>
              <Sidebar game_id={props.game_id} view={view} index={index} listings={listings} loading={loading} city={city}/>

            </Box>
            </Grid>

            </Grid>
            </Box>
            </Grid>
            </Grid>
  );
}

export default GameContainer;
