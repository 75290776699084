// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getDatabase} from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDDNrLLDw97RMzcOapp4kAz2RALw5Khr7A",
  authDomain: "realestate-7bff1.firebaseapp.com",
  projectId: "realestate-7bff1",
  storageBucket: "realestate-7bff1.appspot.com",
  messagingSenderId: "352033680796",
  appId: "1:352033680796:web:6bb3829aefa89f77336c59",
  measurementId: "G-FQY0KN2Y4T"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const database = getDatabase(firebase);
const analytics = getAnalytics(firebase);

export default database;
