import React, { useEffect } from 'react';
import {useState} from "react";

import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ResultsRow = (props) => {

  function addCommas(number) {
    if (number){
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }



    let row = <div>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Typography component="h1" variant="h6" align="center">
                {props.user}
              </Typography>
              <Typography component="h1" variant="h6" align="center">
                Accuracy: {props.accuracy}
              </Typography>
              <Typography component="h1" variant="h6" align="center">
                Off by: ${addCommas(props.absolute)}
              </Typography>
              <Typography component="h1" variant="h6" align="center">
                Guess: ${addCommas(props.guess)}
              </Typography>

            </Stack>
          </div>


  return (
    <Box sx={{ mt: 2 }} align="center">
      {row}
    </Box>
  );
};

export default ResultsRow;
