import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Arrow from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import GoogleAds from './GoogleAdSense.js';

export default function LeftSidebar(props) {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
      event.preventDefault();
      console.log("setting loading");
      // set(ref(database, '/games/' + props.game_id + "/state/listings/"), {
      //   loading: true,
      // });
      // const data = new FormData(event.currentTarget);
      // let guess = data.get('city').toLowerCase();
      // sendData(guess)
    }
//<Button variant="contained">Multi Player Mode</Button>
// <Typography variant="h6" align="center">
//   Search specific city:
// </Typography>
// </Box>
//       <Box component="form" onSubmit={handleSubmit}  noValidate sx={{ mt: 1, mb: 2 }}>
//         <TextField
//           id="city"
//           name="city"
//           fullWidth="true"
//           justifyContent="center"
//           align="center"
//           variant="outlined" />
//       </Box>

    return (
    <div>

      <Box sx={{ mt: 6 }}>
        <GoogleAds slot="8077970410"/>
      </Box>


    </div>
    );
  }
