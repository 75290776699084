import React, { useCallback, useEffect, useRef, useState } from "react";


export default function RoundedImage(props) {

    const click = () => {
      props.onclick(props.ind);
    }

    return (
      <div
        style={{
          borderRadius: `${props.radius}px`,
          overflow: 'hidden',
        }}
      >
        <img src={props.imageUrl} alt="Rounded image" onClick={click}
        style={{
          width: '100%', // Set the width to 100% to make the image fill the container
          height: 'auto', // Automatically adjust the height to maintain the aspect ratio
        }}
        />
      </div>
    );
  };
