import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';


import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import axios from 'axios';
import spinning_house from '../images/spinning_house.gif';


function GuessInput(props) {
  const [accuracy, setAccuracy] = useState(null);
  const [abs, setAbs] = useState(null);


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let guess = data.get('guess').toLowerCase();
    if (isNaN(guess)) {
      return; //TODO: add error
    }
    let guess_int = parseInt(guess, 10);
    let accuracy = calculateScorePercent(guess_int, props.price);
    let absolute = calculateScoreAbs(guess_int, props.price);
    setAccuracy(accuracy);
    setAbs(absolute);
    set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
            accuracy: accuracy,
            absolute: absolute,
            guess: guess_int,
            price: props.price,
            next:false,
    });
  }

  function calculateScorePercent(guess, real) {
        let difference = Math.abs(guess - real); // calculate the absolute difference between the guess and the real number
        let score_percent = fractionToPercent((difference / real));
        return score_percent;
    }

  function calculateScoreAbs(guess, real) {
        let difference = Math.abs(guess - real); // calculate the absolute difference between the guess and the real number
        return difference;
    }

  function fractionToPercent(fraction) {
    return (fraction * 100).toFixed(2) + "%";
  }



  return (
    <Box sx={{ mt: 5 }}>
      <Box component="form" onSubmit={handleSubmit}  noValidate sx={{ mt: 1 }}>
      <Box sx={{ mb: 1 }}>
      Guess the price:
      </Box>
         <OutlinedInput
            id="guess"
            name="guess"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
      </Box>
    </Box>
  );
}

// <TextField
//   id="outlined-basic"
//   id="city"
//   name="city"
//   label="enter price guess"
//   fullWidth="true"
//   startAdornment={<InputAdornment position="start">$</InputAdornment>}
//   justifyContent="center"
//   align="center"
//   variant="outlined" />

export default GuessInput;
