import React, { useCallback, useEffect, useRef, useState } from "react";


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import house from '../images/house.gif';

import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import database from '../firebase_database.js';

const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


export default function Landing(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [game, setGame] = React.useState();
  const [copied, setCopied] = React.useState(false);


  function generateGame() {
    let type = "zillow";

    const shortName = uniqueNamesGenerator({
      dictionaries: [adjectives, animals, colors], // colors can be omitted here as not used
      length: 2
    }); // big-donkey
    const gameref = ref(database, '/valid_games/' + shortName);
    setGame(shortName);
    // create in DB
    set(ref(database, '/valid_games/' + shortName), {
      started: false,
      ended: false,
      type: type,
    });
    setOpen(true);

  };
  const copyText = () => {
    navigator.clipboard.writeText(game);
    setCopied(true);
  }

  return (
  <div>
  <Box
    sx={{
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
  <Typography component="h1" variant="h1">Renter's Woe</Typography>
    <img src={house} width="300" />
    <Button color="primary" size="large" variant="contained" onClick={generateGame}>Start New Game </Button>
    <Button href="form" color="primary" size="large" variant="contained" sx={{ my: 1, mx: 1.5}}>
      Join Existing Game
    </Button>
    </Box>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
    <Box sx={{ ...style, width: 400 }}>
      <h2 id="parent-modal-title">Use this game ID:
      <Button onClick={copyText}>{game}</Button>
      </h2>
      <Collapse in={copied}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCopied(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Copied to clipboard
        </Alert>
    </Collapse>
      <Button href="form" color="primary" size="large" variant="contained" sx={{ my: 1, mx: 1.5}}>
        Start Game
      </Button>

      </Box>
    </Modal>
  </div>
  );
}
