import React, { useCallback, useEffect, useRef, useState } from "react";
import LeftSidebar from './left_sidebar.js';
import RightSidebar from './right_sidebar.js';

import MainGame from './main_game.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import url_list from '../../url_list.js';
// import properties from '../../properties.json';
import spinning_house from '../../images/spinning_house.gif';

import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


// https://renters-woe-backend.herokuapp.com/api/url_details
// http://localhost:5000/api/url_details
export default function Container(props) {
    const [open, setOpen] = React.useState(false);
    const [i, setI]  = useState(0);
    const [listing, setListing] = useState();
    const [sqft, setSQFT] = useState();
    const [photos, setPhotos] = useState();
    const [city, setCity] = useState();
    const [bedrooms, setBedrooms] = useState();
    const [bathrooms, setBathrooms] = useState();
    const [state, setState] = useState();
    const [price, setPrice] = useState();
    const [description, setDescription] = useState();
    const [accuracyList, setAccuracyList] = useState([]);
    const [score, setScore] = useState("");
    const [bestScore, setBestScore] = useState(0);
    const [worstScore, setWorstScore] = useState(1);
    const [propIndex, setPropIndex]= useState(Math.floor(Math.random() * 273));

    // async function sendData(data) {
    //   try {
    //       const response = await fetch('https://renters-woe-backend.herokuapp.com/api/url_details', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //       });
    //       const json = await response.json();
    //       console.log(json);
    //       setListing(json);
    //       setSQFT(json["livingAreaValue"]);
    //       setPhotos(json["originalPhotos"]);
    //       setCity(json["city"]);
    //       setBedrooms(json["bedrooms"]);
    //       setBathrooms(json["bathrooms"]);
    //       setState(json["state"]);
    //       setDescription(json["description"]);
    //       if (json["price"] > 0) {
    //         setPrice(json["price"])
    //       } else {
    //         setPrice(json["zestimate"]);
    //       }
    //
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }

    // const sendDataBelow = (url) => {
    //   sendData(url);
    // }



      const sendDataBelow = (property) => {
        setPropertyData(property);
      }

      const setBest = (a) => {
        setBestScore(a);
      }

      const setWorst = (a) => {
        setWorstScore(a);
      }
      const changePropIndex = () => {
        //CHANGE THIS MAGIC NUMBER (273) WHEN YOU UPDATE LISTINGS IN DATABASE
        let index = Math.floor(Math.random() * 273);
        setPropIndex(index);
      }

      function CalculateAccuracyString(aList) {
        let weights = [1, 2, 3, 4, 5];
        let weightedAverage = aList.reduce((acc, number, idx) => acc + number*weights[idx],0) / weights.reduce((acc, w) => acc + w)
        let percent = (weightedAverage * 100).toFixed(2) + "%";
        setScore(percent);
      }

      const updateAccuracyList = (new_a) => {
        let aList = accuracyList;
        if (accuracyList.length > 4) {
          let oldA = aList.shift();
          aList.push(new_a);
          CalculateAccuracyString(aList);
        } else {
          aList.push(new_a);
          let sum = aList.reduce((accumulator, currentValue) => accumulator + currentValue);
          let average = sum / aList.length;
          let percent = (average * 100).toFixed(2) + "%";
          setScore(percent);
        }
        setAccuracyList(aList);
      }

      const resetL = () => {
        setListing();
      }

      function chooseRandomItem(items) {
        return items[Math.floor(Math.random() * Object.keys(items).length)][0];
      }

      function setPropertyData(property) {
        setListing(property);
        setSQFT(property["livingAreaValue"]);
        setPhotos(property["originalPhotos"]);
        setCity(property["city"]);
        setBedrooms(property["bedrooms"]);
        setBathrooms(property["bathrooms"]);
        setState(property["state"]);
        setDescription(property["description"]);
        if (property["price"] > 0) {
          setPrice(property["price"])
        } else {
          setPrice(property["zestimate"]);
        }
      }

      useEffect(() => {
        //CHANGE THIS MAGIC NUMBER (273) WHEN YOU UPDATE LISTINGS IN DATABASE
        // let index = Math.floor(Math.random() * 273);
        console.log(propIndex);
        const vref = ref(database, propIndex.toString());
          onValue(vref, (snapshot) => {
            const property = snapshot.val()[0];
            console.log(property);
            setPropertyData(property)
          });
          return () => off(vref);
        }, [propIndex]); // Include propIndex in the dependency array


  const setIndex = (index) => {
    setI(index);
  }
  let show;
  if (listing) {
    show = <MainGame index={i}
                    setBest={setBest}
                    setWorst={setWorst}
                    worstScore={worstScore}
                    bestScore={bestScore}
                    updateAccuracyList={updateAccuracyList}
                    setIndex={setIndex}
                    listing={listing}
                    sqft={sqft}
                    bathrooms={bathrooms}
                    state={state}
                    price={price}
                    photos={photos}
                    description={description}
                    city={city}
                    bedrooms={bedrooms}
                    sendData={sendDataBelow}
                    setPropIndex={changePropIndex}
                    resetL={resetL}/>
  } else {
    show =
      <Box sx={{display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'}}>
        <img src={spinning_house} width="400" />
      </Box>
  }

    return (
    <div>
    <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
            <Box sx={{mt:4, ml:2, mr: 2}}>
              <LeftSidebar/>
                </Box>
                </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ ml:2}}>
            {show}
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
            <Box sx={{mt:4, ml:2, mr: 2}}>
              <RightSidebar worstScore={worstScore} setPropIndex={changePropIndex} bestScore={bestScore} sendData={sendDataBelow} resetL={resetL} score={score}/>
            </Box>
        </Grid>
    </Grid>
    </div>
    );
  }
